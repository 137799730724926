import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmailValidatorService {
  // tslint:disable-next-line
  private REG_EXP = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;
  
  constructor() { }

  validateEmail(email: string): boolean {
    if (email == null || email === "") {
      return true;
    }
    
    return this.REG_EXP.test(email);
  }

  validateEmailList(emailListString = ''): boolean {
    emailListString = (emailListString || '').trim();
    let emails = emailListString.split(';').filter(Boolean);
    
    if (!emails.length) return false;
    
    for (let email of emails) {
      email = email.trim();
      
      if (!this.validateEmail(email)) {
        return false;
      }
    }
    return true;
  }
}
