import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import Contact from 'src/app/client/client-detail/client-people/contact';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private httpClient: HttpClient) { }

  getContactsByAccountId(accountId: number) {
    return this.httpClient.get<Contact[]>(`${environment.apiUrl}/api/Accounts/${accountId}/Contacts`);
  }

  getContact(id: number) {
    return this.httpClient.get<Contact>(`${environment.apiUrl}/api/Contacts/${id}`);
  }

  patchContact(id:number, patchDoc: any){
    return this.httpClient.patch(`${environment.apiUrl}/api/Contacts/${id}`, patchDoc);
  }

  postContact(contact: Contact){
    return this.httpClient.post(`${environment.apiUrl}/api/Contacts`, contact);
  }
}
