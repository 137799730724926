export enum Roles {
  "Activity Reports" = 1,
  "Backup Claims" = 2,
  "Backup Hearings" = 3,
  Billing = 4,
  "Charge Statements" = 5,
  Claims = 6,
  "Contribution Reports" = 7,
  Hearings = 8,
  "No Appeal Authority" = 9,
  "No Protest Authority" = 10,
  "Tax Rate Notices" = 11,
  Wage = 12,
  Workshops = 13,
  Letters = 14
}
